import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import "./DriversList.scss";
import Filters from "./Filters/Filters";
import { generateListApiParams } from "../../../helpers/helpers";
import Table, { DRIVERS_LIST_TABLE_WRAPPER } from "./Table/Table";
import { useCallback } from "react";
import { format, sub } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Loader } from "../../../assets/icons/loader.svg";
import { ScrollTop } from "components/ScrollTop";
import { useMediaQuery } from "react-responsive";
import { MenuToggle } from "components/MenuToggle/MenuToggle";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";
import { api } from "api";
import { useIsMobile } from "hooks/useMobile";
import { Button, Drawer, Radio } from "antd";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";

const DRIVERS_TABLE_ID = "driver_list_table";

const initialParams = (id) => generateListApiParams(id, "id", true, 1, 25);

const MOBILE_BULK_INVITE_OPTIONS = {
  ALL: "all",
  FIRST_15: "FIRST_15",
  FIRST_30: "FIRST_30",
  SELECTED: "SELECTED",
};

const filterOnlyNotOnboarded = (driversList) => {
  return driversList.filter(({ signed_up_at }) => !signed_up_at);
};

const DriversList = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isXXSMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentCompanyId: id } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });

  // Component state
  const [showTableLoader, setShowTableLoader] = useState(true);
  const [selectedDrivers, setSelectedDrivers] = useState({});
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showMobileBulkInviteModal, setShowMobileBulkInviteModal] =
    useState(false);
  const [mobileBulkInviteSelectedOption, setMobileBulkInviteSelectedOption] =
    useState(MOBILE_BULK_INVITE_OPTIONS.ALL);

  // Api state
  const [params, setParams] = useState(null);
  const [inputValue, setInputValue] = useState("");

  // Queries and mutations
  const resetApiState = useCallback(() => {
    setSelectedDrivers({});
    dispatch(
      api.util.updateQueryData("getDriversList", {}, (drivers) => {
        drivers.data = [];
      })
    );
  }, [dispatch]);
  const setDriverInvited = useCallback(
    (driverId) => {
      dispatch(
        api.util.updateQueryData("getDriversList", {}, (drivers) => {
          drivers.data = drivers.data.map((driver) => {
            if (driver.id === driverId) {
              return {
                ...driver,
                invited_at: new Date().toString(),
              };
            }

            return driver;
          });
        })
      );
    },
    [dispatch]
  );
  const {
    data: drivers,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = api.endpoints.getDriversList.useQuery(params, {
    skip: !params,
  });

  const isUninitialized = typeof drivers === "undefined";

  const areSelectedDrivers = Object.keys(selectedDrivers).length;
  const loading = isLoading || isFetching;
  const driversList = useMemo(() => drivers?.data || [], [drivers?.data]);

  const paginationInfo = useMemo(() => {
    const pagination = drivers?.meta;

    if (!pagination) {
      return;
    }

    setShowTableLoader(false);

    return {
      lastPage: pagination.last_page,
      currentPage: pagination.current_page,
      total: pagination.total,
    };
  }, [drivers?.meta]);

  // Mutations
  const [inviteDriverBulk] = api.endpoints.inviteDriverBulk.useMutation();

  // Effects
  useEffect(() => {
    if (id) {
      setParams(initialParams(id));
      setShowTableLoader(true);
      resetApiState();
    }
  }, [id, resetApiState]);

  useEffect(() => {
    if (isError) {
      setShowTableLoader(false);
    }
  }, [isError]);

  // Handlers
  const handleSelectDriver = (driver) => {
    const driverId = driver.id;
    setSelectedDrivers((currentDrivers) => {
      if (currentDrivers[driverId]) {
        return Object.keys(currentDrivers).reduce(
          (prevDrivers, currentDriverId) => {
            if (currentDriverId.toString() === driverId.toString()) {
              return prevDrivers;
            }

            return {
              ...prevDrivers,
              [currentDriverId]: currentDrivers[currentDriverId],
            };
          },
          {}
        );
      }

      return {
        ...currentDrivers,
        [driverId]: driver,
      };
    });
  };

  const onSearch = useCallback(
    (e) => {
      if (e.target.value) {
        resetApiState();
        setShowTableLoader(true);
        setParams((currentParams) => ({
          ...currentParams,
          term: e.target.value,
          page: 1,
        }));
      } else {
        resetApiState();
        setShowTableLoader(true);
        setParams(initialParams(id));
      }
    },
    [id, resetApiState]
  );

  const onClearSearch = useCallback(
    (val) => {
      if (!val) {
        resetApiState();
        setShowTableLoader(false);
        setParams(initialParams(id));
      }
      setInputValue(val);
    },
    [id, resetApiState]
  );

  const getNextDrivers = useCallback(() => {
    setParams((currentParams) => ({
      ...currentParams,
      page: paginationInfo?.currentPage + 1,
    }));
  }, [paginationInfo?.currentPage]);

  const onRowClick = useCallback(
    (driverId, trips) => {
      if (!trips) {
        return;
      }
      const firstDate = sub(new Date(), { months: 1 });
      const lastDate = new Date();
      const params = new URLSearchParams();
      params.append("firstDate", format(firstDate, "yyyy-MM-dd"));
      params.append("lastDate", format(lastDate, "yyyy-MM-dd"));
      params.append("driverId", driverId);
      navigate(`/console/transactions?${params.toString()}`);
    },
    [navigate]
  );

  const filteredSelectedDrivers = useMemo(() => {
    const selectedDriversFiltered = filterOnlyNotOnboarded(
      Object.values(selectedDrivers)
    );

    return selectedDriversFiltered.map(({ id }) => id);
  }, [selectedDrivers]);

  const bulkInviteDrivers = useCallback(
    (driverIds) => {
      if (!driverIds.length) {
        return showErrorMsg({
          content: "Selected drivers are already onboarded!",
        });
      }

      showLoadingMsg({
        content: "Inviting Drivers!",
      });
      inviteDriverBulk({
        ids: driverIds,
      })
        .unwrap()
        .then(() => {
          showSuccessMsg({
            content: "Succesfully invited drivers!",
          });
        })
        .catch(() => {
          showErrorMsg({
            content: "Some error occurred!",
          });
        });
    },
    [inviteDriverBulk]
  );

  const onBulkInvite = useCallback(() => {
    if (!isMobile) {
      bulkInviteDrivers(filteredSelectedDrivers);
    } else {
      if (areSelectedDrivers) {
        setMobileBulkInviteSelectedOption(MOBILE_BULK_INVITE_OPTIONS.SELECTED);
      } else {
        setMobileBulkInviteSelectedOption(MOBILE_BULK_INVITE_OPTIONS.FIRST_15);
      }
      setShowMobileBulkInviteModal(true);
    }
  }, [
    areSelectedDrivers,
    bulkInviteDrivers,
    isMobile,
    filteredSelectedDrivers,
  ]);

  const onTableScroll = useCallback((e) => {
    const tableWrapper = document.querySelector(
      `.${DRIVERS_LIST_TABLE_WRAPPER}`
    );
    const position = tableWrapper.getBoundingClientRect();
    if (position.y > 0) {
      setShowScrollToTop(false);
    } else setShowScrollToTop(true);
  }, []);

  const onRefetch = () => {
    resetApiState();
    if (params.page === 1) {
      refetch();
      return;
    }
    setParams((curr) => ({ ...curr, page: 1 }));
  };

  const first15and30orAllNonSelectedDrivers = useMemo(() => {
    return {
      first15: filterOnlyNotOnboarded(driversList.slice(0, 15)).map(
        ({ id }) => id
      ),
      first30: filterOnlyNotOnboarded(driversList.slice(0, 15)).map(
        ({ id }) => id
      ),
      all: filterOnlyNotOnboarded(driversList).map(({ id }) => id),
    };
  }, [driversList]);

  const onMobileInviteDriversSubmit = useCallback(() => {
    setShowMobileBulkInviteModal(false);
    let selectedDriverIds = filteredSelectedDrivers;
    const isFirst15Or30 = [
      MOBILE_BULK_INVITE_OPTIONS.FIRST_15,
      MOBILE_BULK_INVITE_OPTIONS.FIRST_30,
    ].includes(mobileBulkInviteSelectedOption);
    if (isFirst15Or30) {
      if (
        mobileBulkInviteSelectedOption === MOBILE_BULK_INVITE_OPTIONS.FIRST_15
      ) {
        selectedDriverIds = first15and30orAllNonSelectedDrivers.first15;
      }
      if (
        mobileBulkInviteSelectedOption === MOBILE_BULK_INVITE_OPTIONS.FIRST_30
      ) {
        selectedDriverIds = first15and30orAllNonSelectedDrivers.first30;
      }
    }
    if (mobileBulkInviteSelectedOption === MOBILE_BULK_INVITE_OPTIONS.ALL) {
      selectedDriverIds = first15and30orAllNonSelectedDrivers.all;
    }
    bulkInviteDrivers(selectedDriverIds);
  }, [
    bulkInviteDrivers,
    first15and30orAllNonSelectedDrivers.all,
    first15and30orAllNonSelectedDrivers.first15,
    first15and30orAllNonSelectedDrivers.first30,
    mobileBulkInviteSelectedOption,
    filteredSelectedDrivers,
  ]);

  const pageHeader = useMemo(() => {
    return (
      <>
        <MenuToggle />
        <div className="drivers-header">
          <h1>Drivers</h1>
        </div>
      </>
    );
  }, []);

  return (
    <div className="drivers-wrapper">
      <Table
        data={driversList}
        loading={showTableLoader}
        queryLoading={loading}
        id={id}
        getNextDrivers={getNextDrivers}
        currentPage={paginationInfo?.currentPage || 0}
        lastPage={paginationInfo?.lastPage || 0}
        onRowClick={onRowClick}
        topScrollId={DRIVERS_TABLE_ID}
        selectDriver={handleSelectDriver}
        selectedDrivers={selectedDrivers}
        refetch={onRefetch}
        headerSection={
          <div className="actions-header-section">
            {isXXSMobile ? (
              <div className="drivers-sticky-header">{pageHeader}</div>
            ) : (
              pageHeader
            )}
            {id && (
              <Filters
                id={id}
                driversList={driversList}
                onSearch={onSearch}
                inputValue={inputValue}
                setInputValue={setInputValue}
                isMobile={isMobile}
                selectedDrivers={selectedDrivers}
                onBulkInvite={onBulkInvite}
                onClearSearch={onClearSearch}
                onRefresh={onRefetch}
                disabledBulk={loading || showTableLoader}
              />
            )}
          </div>
        }
        isUninitialized={isUninitialized}
        setDriverInvited={setDriverInvited}
        onScroll={onTableScroll}
      />
      <div className="drivers-footer">
        <span>
          {loading && <Loader />}
          <h3>
            {loading && "Loading"} <b>{driversList?.length || 0} </b> of{" "}
            <b>{paginationInfo?.total}</b>
          </h3>
        </span>
        {showScrollToTop && <ScrollTop scrollId={DRIVERS_TABLE_ID} />}
      </div>
      <Drawer
        closable={false}
        placement="bottom"
        visible={showMobileBulkInviteModal}
        height="auto"
        onClose={() => {
          setShowMobileBulkInviteModal((prevState) => !prevState);
        }}
        className="drivers-mobile-bulk-invite-modal"
      >
        <div>
          <p className="drawer-title">Invite</p>
          {areSelectedDrivers ? (
            <Radio
              onChange={() =>
                setMobileBulkInviteSelectedOption(
                  MOBILE_BULK_INVITE_OPTIONS.SELECTED
                )
              }
              checked={
                mobileBulkInviteSelectedOption ===
                MOBILE_BULK_INVITE_OPTIONS.SELECTED
              }
            >
              Selected Drivers ({filteredSelectedDrivers.length} not signed up)
            </Radio>
          ) : (
            <>
              <Radio
                onChange={() =>
                  setMobileBulkInviteSelectedOption(
                    MOBILE_BULK_INVITE_OPTIONS.FIRST_15
                  )
                }
                checked={
                  mobileBulkInviteSelectedOption ===
                  MOBILE_BULK_INVITE_OPTIONS.FIRST_15
                }
              >
                First 15 drivers (
                {first15and30orAllNonSelectedDrivers.first15.length} not signed
                up)
              </Radio>
              <Radio
                onChange={() =>
                  setMobileBulkInviteSelectedOption(
                    MOBILE_BULK_INVITE_OPTIONS.FIRST_30
                  )
                }
                checked={
                  mobileBulkInviteSelectedOption ===
                  MOBILE_BULK_INVITE_OPTIONS.FIRST_30
                }
              >
                First 30 drivers (
                {first15and30orAllNonSelectedDrivers.first30.length} not signed
                up)
              </Radio>
            </>
          )}
          <Radio
            onChange={() =>
              setMobileBulkInviteSelectedOption(MOBILE_BULK_INVITE_OPTIONS.ALL)
            }
            checked={
              mobileBulkInviteSelectedOption === MOBILE_BULK_INVITE_OPTIONS.ALL
            }
          >
            All Drivers ({first15and30orAllNonSelectedDrivers.all.length} not
            signed up)
          </Radio>
          <Button onClick={onMobileInviteDriversSubmit}>Send Invite</Button>
        </div>
      </Drawer>
    </div>
  );
};

export default DriversList;
